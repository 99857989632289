import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Loader } from '~/components/Loader/loader';
import { useUpdateUserData } from '~/adapters/services/accounts/users/hooks';
import { AutofillTextField } from '../PostalAddresses/AddressDialog/AutofillTextField';

interface ChangeNameDialogProps {
    open: boolean;
    close: EmptyFunction;
    currentFirstName?: string | null;
    currentLastName?: string | null;
    userId: number;
}

const ChangeNameStack = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));

export function ChangeNameDialog({ currentFirstName, currentLastName, userId, close, open }: ChangeNameDialogProps) {
    const [firstName, setFirstName] = useState(currentFirstName ?? '');
    const [lastName, setLastName] = useState(currentLastName ?? '');
    const { mutate, isPending } = useUpdateUserData();

    const submit = useCallback(() => {
        mutate({ firstName, lastName, userId }, { onSettled: close });
    }, [mutate, firstName, lastName, userId, close]);
    return (
        <Dialog open={open} onClose={close} title="Change Name" width="md" mobileVariant="fullScreen">
            <DialogContent pt={2}>
                <ChangeNameStack>
                    <AutofillTextField
                        name="firstName"
                        label="First Name"
                        autoFillKey="given-name"
                        value={firstName}
                        onChange={(ev) => setFirstName(ev.target.value)}
                        fullWidth
                    />
                    <AutofillTextField
                        name="lastName"
                        label="Last Name"
                        inputProps={{ autoComplete: 'family-name' }}
                        value={lastName}
                        onChange={(ev) => setLastName(ev.target.value)}
                        fullWidth
                    />
                </ChangeNameStack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="secondary">
                    Cancel
                </Button>
                <Button data-testid="change name dialog button" onClick={submit} disabled={isPending}>
                    {isPending ? <Loader title="Saving..." /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
