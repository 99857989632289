import * as Sentry from '@sentry/react';
import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useMemo } from 'react';
import { AuthUserDetailDto, ChangePasswordRequest, SetPasswordRequest } from '~/adapters/services/accounts/auth0/types';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';

class Auth0Repository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public verifyUser = (sessionToken: string) => {
        if (!sessionToken) {
            Sentry.captureException(
                'Verify user should be always called with session token, but no sessionToken was given',
            );
        }
        return this.accountsApiCall(`/auth0/verify`, {
            method: 'POST',
            params: {
                sessionToken,
            },
        });
    };

    public setPassword = async (data: SetPasswordRequest) => {
        return this.accountsApiCall<void>(`/auth0/set-password`, {
            method: 'POST',
            data,
        });
    };

    public changePassword = async (data: ChangePasswordRequest) => {
        return this.accountsApiCall<void>(`/auth0/change-password`, {
            method: 'POST',
            data,
        });
    };

    public getAuthUserDetail = async () => {
        const response = await this.accountsApiCall<AuthUserDetailDto>(`/auth0/user`);
        return response.data;
    };
}

export const useAuth0Repository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new Auth0Repository(accountsApiCall), []);
};
