import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0Repository } from '~/adapters/services/accounts/auth0/rest';
import timeoutPromise from '~/adapters/browser/timeoutPromise';
import {
    pushApiErrorNotification,
    pushErrorNotification,
    pushSuccessNotification,
} from '~/adapters/notistack/notistack';
import { isAxiosError } from '~/adapters/axios/isAxiosError';
import { Auth0Error } from '~/adapters/services/accounts/auth0/types';
import * as Sentry from '@sentry/react';

const auth0UserInfoKey = ['auth-user-info'];

export function useVerifyUser() {
    const { verifyUser } = useAuth0Repository();

    return useMutation({
        mutationFn: verifyUser,
        onSuccess() {
            pushSuccessNotification('Verification email resent, please check your inbox.');
            return timeoutPromise(60000, undefined);
        },
        onError() {
            pushErrorNotification("Couldn't resend verification email. Please login again in a different tab.");
        },
    });
}

export const useSetPassword = ({
    onUserNotMigratedToAuth0Exception,
}: {
    onUserNotMigratedToAuth0Exception: () => void;
}) => {
    const { setPassword } = useAuth0Repository();
    const client = useQueryClient();

    return useMutation({
        mutationFn: setPassword,
        onSuccess() {
            client.setQueryData(auth0UserInfoKey, { hasPassword: true } satisfies ReturnType<
                typeof useGetAuthUserDetail
            >['data']);
            pushSuccessNotification('Password was set.');
        },
        onError(error) {
            if (isAxiosError(error)) {
                const errorType = (error.response?.data as Auth0Error).error.type;
                if (errorType === 'USER_NOT_MIGRATED_TO_AUTH0') {
                    Sentry.captureException(error, {
                        level: 'warning',
                    });
                    onUserNotMigratedToAuth0Exception();
                    return;
                }
            }
            Sentry.captureException(error, {
                level: 'error',
            });
            pushApiErrorNotification('Your password could not be set.');
        },
    });
};

export const useChangePassword = ({ onInvalidPassword }: { onInvalidPassword?: () => void }) => {
    const { changePassword } = useAuth0Repository();

    return useMutation({
        mutationFn: changePassword,
        onSuccess: () => {
            pushSuccessNotification('Password was changed.');
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                const errorType = (error.response?.data as Auth0Error).error.type;
                if (errorType === 'INVALID_CURRENT_PASSWORD') {
                    onInvalidPassword?.();
                    return;
                }
            }
            Sentry.captureException(error, {
                level: 'error',
            });
            pushApiErrorNotification('Your password could not be changed.');
        },
    });
};

export const useGetAuthUserDetail = () => {
    const { getAuthUserDetail } = useAuth0Repository();

    return useQuery({
        queryKey: auth0UserInfoKey,
        queryFn: () => getAuthUserDetail(),
    });
};
