import { forwardRef } from 'react';
import { TextField as MUITextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

export type AutofillTextFieldProps = TextFieldProps & { autoFillKey?: string };

// MUI TextField that facilitates automatic address autoFill by browser (autoComplete attribute on input element)
// The name autoFill is chosen to distinguish from MUI Autocomplete component (but AutofillTextField is also used for MUI Autocomplete)
// Pigment TextField doesn't work with react-hook-form because of forwardRef, that's why we use MUI
export const AutofillTextField = forwardRef<HTMLDivElement, AutofillTextFieldProps>(
    ({ inputProps, helperText, autoFillKey, ...props }, ref) => {
        return (
            <MUITextField
                ref={ref}
                inputProps={{ ...inputProps, autoComplete: autoFillKey }}
                helperText={helperText || ' '}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        );
    },
);
